import type { Product } from "src/@types/product";

export function sortOnlyUpdatedData(data: Product[], dbData: any) {
	return data.map((item) => {
		const dbItem = dbData?.find((db: any) => db[0].id === item.id);

		return (Object.keys(item) as (keyof Product)[]).reduce(
			(acc, key) => {
				if (key === "metadata" && Array.isArray(item.metadata)) {
					const updatedMetadata = item.metadata.reduce((metaAcc, meta) => {
						if (meta.value === "") {
							meta.value = "NC";
						}

						const existingMeta = dbItem?.[0]?.metadata?.find(
							(m: any) => m.key === meta.key,
						);
						if (
							(existingMeta && existingMeta.value !== meta.value) ||
							(!existingMeta && meta.value !== "")
						) {
							metaAcc = metaAcc ? [...metaAcc, meta] : [meta];
						}

						return metaAcc;
					}, [] as any[]);

					if (updatedMetadata.length > 0) {
						acc.metadata = updatedMetadata;
					}
				} else {
					acc[key] = item[key];
				}

				return acc;
			},
			{} as Partial<Product>,
		);
	});
}
