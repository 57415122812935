import { sortOnlyUpdatedData } from "src/functions/sortOnlyUpdatedData";
import type {
	IProduct,
	Product,
	IPagination,
	resultHeavy,
} from "../../@types/product";
import { WORKERS } from "../../config-global";
// utils
import axios from "../../utils/axios";
type RowData = {
	[key: string]: string;
};
const queryParameters = new URLSearchParams(window.location.search);
const triggerSecretKey = process.env.REACT_APP_TRIGGER_SECRET_KEY
	? `?triggerSecretKey=${process.env.REACT_APP_TRIGGER_SECRET_KEY}`
	: "";

const generateHandle = (title: string, barcode: string): string => {
	const cleanedTitle = title
		? title
				.normalize("NFD")
				// biome-ignore lint/suspicious/noMisleadingCharacterClass: <explanation>
				.replace(/[\u0300-\u036f]/g, "")
		: "";
	const cleanedBarcode = barcode
		.normalize("NFD")
		// biome-ignore lint/suspicious/noMisleadingCharacterClass: <explanation>
		.replace(/[\u0300-\u036f]/g, "");

	const lowercaseTitle = cleanedTitle.toLowerCase().replace(/[^a-z0-9\s]/g, "");
	const lowercaseBarcode = cleanedBarcode
		.toLowerCase()
		.replace(/[^a-z0-9\s]/g, "");

	const titleWithHyphens = lowercaseTitle.replace(/\s+/g, "-");
	const barcodeWithHyphens = lowercaseBarcode.replace(/\s+/g, "-");

	const handle = `${titleWithHyphens}-${barcodeWithHyphens}`;

	return handle;
};
const dvdTypes = ["DVD", "4K Ultra HD", "HD DVD", "Blu-ray 3D", "Blu-ray"];
const bookTypes = [
	"Comics",
	"Grand format",
	"MANGA",
	"Beaux-livres",
	"Jeux et livre coquins",
	"Poche",
];
const merchTypes = ["Merchandising"];
const musicTypes = ["CD de musique"];

const getDefaultImage = (productType: string): string => {
	if (dvdTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_dvd.jpg?v=1722344708";
	}
	if (bookTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_book.jpg?v=1722344708";
	}
	if (merchTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_merch.jpg?v=1722344708";
	}
	if (musicTypes.includes(productType)) {
		return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img_music.jpg?v=1722344708";
	}
	return "https://cdn.shopify.com/s/files/1/0793/6776/6332/files/MDP_default_img.jpg?v=1722344708";
};

export function convertRowDataToProduct(
	rowData: RowData[],
	isUpdate = false,
	productChannels?: string[],
	isSingleUpdate = false,
): Product[] {
	const trueValues = new Set([
		"true",
		"1",
		1,
		"yes",
		"oui",
		"vrai",
		"continue",
	]);
	const falseValues = new Set(["false", "0", 0, "no", "non", "faux", "deny"]);

	const getSKUPrefix = (productType: string) => {
		if (dvdTypes.includes(productType)) return "VID";
		if (bookTypes.includes(productType)) return "PAP";
		if (merchTypes.includes(productType)) return "MER";
		if (musicTypes.includes(productType)) return "MUS";
		return "MDP";
	};

	return rowData.map((data) => {
		const metadata = Object.entries(data)
			.filter(
				([key, value]) => key.startsWith("meta_") && (isUpdate || value !== ""),
			)
			.map(([key, value]) => ({
				key: key.slice(5),
				value: value?.toString() || "",
			}));

		const imagesSrc = (data["Images Src"] || data["images src"] || "")
			.split(",")
			.map((src) =>
				src.endsWith("no_image.png") || src.includes("no_image")
					? getDefaultImage(data["Product Type"] || "")
					: src,
			);
		const images =
			imagesSrc.length > 0 && imagesSrc.some((src) => src)
				? imagesSrc.map((src) => ({ src }))
				: undefined;

		const product: Product = {
			body_html: data.Description || data["Body (HTML)"] || "",
			title: data.Title,
			code: data.code || "",
			metadata,
			product_type: data["Product Type"] || "",
			sku: data.SKU || "",
			status: "active",
			variants: [
				{
					position: data["variant position"] || 1,
					barcode: data["Variant Barcode"]?.toString() || "",
					grams: data["Variant Weight"] || "0",
					inventory_management: data.variant_inventory_management || "shopify",
					inventory_policy: trueValues.has(data["Variant Inventory Policy"])
						? "continue"
						: "deny",
					inventory_quantity: 0,
					option1: data["variant option1"] || "Default Title",
					option2: data["variant option2"] || null,
					option3: data["variant option3"] || null,
					price: data["Variant Price"]?.toString() || "0",
					taxable: trueValues.has(data["variant taxable"]) || true,
					sku:
						data["Variant SKU"] ||
						data.variant_sku ||
						`${getSKUPrefix(data["Product Type"])}${data["Variant Barcode"]?.toString() || ""}`,
					title: "Default Title",
					weight: data["Variant Weight"] || "0",
					weight_unit: data["Weight Unit"] || "g",
					requires_shipping:
						!falseValues.has(data["Variant Requires Shipping"]) || true,
					inventories: [],
					fulfillment_service: data["variant fulfillment service"] || "manual",
				},
			],
			template_suffix: data["Template Suffix"] || "",
			tags: data.Tags || "",
			options: [{ name: "Title", values: ["Default Title"] }],
			handle: generateHandle(
				data.Title,
				data["Variant Barcode"]?.toString() || "",
			),
			vendor: data.Vendor,
			...(images ? { images } : {}),
		};

		if (!isSingleUpdate) product.productSync = data.productSync;

		if (isUpdate) {
			product.handle = data.handle;
			product.external_id = data["external id"] || data.external_id;
			product.id = data.id;
			product.options = [
				{
					id: data["option id"] || data.option_id,
					external_id: data["option external id"] || data.option_external_id,
					name: data["option name"] || data.option_name,
					position:
						Number(data["option position"] || data.option_position) || 0,
					values: [data["option values"] || data.option_values],
				},
			];
			const variant = product.variants[0];
			variant.fulfillment_service = "manual";
			variant.id = data.variant_id;
			variant.image_id = data.variant_image_id || null;
			variant.external_id = data.variant_external_id || "";
			variant.inventory_item_id = Number(data.variant_inventory_item_id) || 0;
			product.images =
				imagesSrc.length > 0
					? imagesSrc.map((src) => ({ src }))
					: data.images_id?.split(",").map((id) => ({ id }));
		} else {
			product.publications = productChannels;
		}

		return product;
	});
}

export function convertIProductToRowData(product: IProduct): RowData {
	try {
		let rowData: RowData = {
			handle: product.handle,
			Title: product.title,
			"Variant Price": product.variants?.[0]?.price?.toString() ?? "0",
		};

		//TODO: add stock management

		// options
		if (product.options?.length > 0) {
			// for (let i = 0; i < product.options.length; i++) {
			rowData["option position"] =
				product.options[0].position?.toString() || "";
			rowData["option name"] = product.options[0].name;
			rowData["option values"] = product.options[0]?.values?.join(", ");
			// }
		}

		rowData = {
			...rowData,
			...{
				// "Variant Title": product.variants?.[0]?.title,
				"Product Type": product.product_type,
				"variant option1": product.variants?.[0]?.option1,
				"Variant SKU": product.variants?.[0]?.sku,
				"Variant Barcode": product.variants?.[0]?.barcode,
			},
		};

		// images
		if (product.images?.length > 0) {
			for (const image of product.images) {
				rowData.images_id = `${rowData.images_id ?? ""}${rowData.images_id ? "," : ""}${image.external_id}`;
				rowData["images src"] =
					`${rowData["images src"] ?? ""}${rowData["images src"] ? "," : ""}${image.src}`;
			}
		}

		rowData = {
			...rowData,
			...{
				Tags: product.tags,
				"Variant Requires Shipping":
					product.variants?.[0]?.requires_shipping?.toString() ?? "true",
				"Variant Inventory Policy":
					product.variants?.[0]?.inventory_policy ?? "deny", //deny or continue
				"variant fulfillment service":
					product.variants?.[0]?.fulfillment_service ?? "manual",
				"Variant Weight": product.variants?.[0]?.grams?.toString() ?? "",
				"Variant Weight Unit": "g",
				Description: product.body_html,
				Vendor: product.vendor,
				//inventory traking
				//out of stock purchase
				"Template Suffix": product.template_suffix,
				//published
				// Status: product.status,
			},
		};

		// metadata
		if (product.metadata?.length > 0) {
			for (const meta of product.metadata) {
				if (meta.value === "undefined" || meta.key.startsWith("product_type_"))
					continue;
				rowData[`meta_${meta.key}`] = meta.value;
			}
		}
		rowData = {
			...rowData,
			...{
				// quantity: product.available?.toString() ?? "",
				// "Ppblication Exceptions": product.publicationExceptions?.join(", ") ?? "",
				// "publications Type": product.publicationType || "",
				// publication: product.publications?.join(", ") ?? "",

				// "Created at": product.created_at,
				"external id": product.external_id,
				id: product.id,
				// "Updated at": product.updated_at,
				// Url: product.url,
				variant_id: product.variants?.[0]?.id?.toString() ?? "",
				"variant grams": product.variants?.[0]?.grams?.toString() ?? "",
				// "Variant Inventory Quantity":
				// 	product.variants?.[0]?.inventory_quantity?.toString() ?? "",
				"variant position": product.variants?.[0]?.position?.toString() ?? "",
				variant_image_id: product.variants?.[0]?.image_id?.toString() ?? "",
				variant_external_id:
					product.variants?.[0]?.external_id?.toString() ?? "",
				variant_inventory_item_id:
					product.variants?.[0]?.inventory_item_id?.toString() ?? "",

				//unused option keys
				option_external_id: product.options?.[0]?.external_id?.toString() ?? "",
				//unused variant keys
				productSync: product.productSync ?? "",
				"variant __typename": "product_variant",
				// 	"variant created_at": product.variants?.[0]?.created_at,
				// 	"variant inventory_item_id": product.variants?.[0]?.inventory_item_id?.toString() ?? "",
				// 	"variant inventory_management": "",
				// 	"variant inventory_policy"	: "",
				// 	"variant inventory_quantity": "",
				"variant option2": "",
				"variant option3": "",
				// "variant product": "",
				// 	"variant product_external_id": "",
				// 	"variant requires_shipping": "",
				"variant taxable": "true",
				//	"variant title"
				// 	"variant updated_at": "",
				// 	"variant user": "",
				// 	"variant user_id": "",
			},
		};

		return rowData;
	} catch (e) {
		return {} as RowData;
	}
}

export async function searchProductsRow(
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	query: any = {},
	limit = 100,
	page = 1,
	heavy = false,
): Promise<{ data: RowData[]; pagination: IPagination }> {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.post(
		`${WORKERS.products}/${siteId}/search?heavy=${heavy}`,
		query,
		{
			params: { limit, page },
			headers: {
				Authorization: bearerToken,
			},
		},
	);
	const data = response.data.data.map((product: IProduct) =>
		convertIProductToRowData(product),
	);
	const pagination = response.data.pagination;
	return { data, pagination };
}

export async function getProductById(id: string): Promise<RowData> {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/product?id=${id}`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	return convertIProductToRowData(response.data.data);
}

export async function searchProductsByBarcode(ean: string, heavy = false) {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.post(
		`${WORKERS.products}/${siteId}/search?heavy=${heavy}&limit=100&page=1`,
		{ variants: { barcode: { _eq: ean } } }, //{ variants: { barcode: { _in: eans } } },
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	let result: resultHeavy[] = response.data.data;
	result = result.reduce((acc: resultHeavy[], current) => {
		const x = acc.find((item) => item.handle === current.handle);
		if (!x) {
			return acc.concat([current]);
		}
		return acc;
	}, []);
	return result;
}

export async function getProductsInQueue(): Promise<number> {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) {
		throw new Error("Bearer token non fourni");
	}
	const responseUpdate = await axios.get(
		`${WORKERS.trigger}?taskId=products/update/multi&siteId=${siteId}${triggerSecretKey}`,
	);
	const responseCreate = await axios.get(
		`${WORKERS.trigger}?taskId=products/create/multi&siteId=${siteId}${triggerSecretKey}`,
	);

	const extractLength = (tag: string): number => {
		const match = tag.match(/length_(\d+)/);
		return match ? Number.parseInt(match[1], 10) : 0;
	};

	const updateLength = responseUpdate.data?.data?.inProgress?.[0]?.tags?.[0]
		? extractLength(responseUpdate.data.data.inProgress[0].tags[0])
		: 0;
	const createLength = responseCreate.data?.data?.inProgress?.[0]?.tags?.[0]
		? extractLength(responseCreate.data.data.inProgress[0].tags[0])
		: 0;
	return updateLength + createLength;
}

// >--------------- LEGACY CODE ---------------<
// export async function deleteProductsInQueue(runId: string) {
// 	const siteId = queryParameters.get("siteId");
// 	const bearerToken = queryParameters.get("act");
// 	if (!bearerToken) {
// 		throw new Error("Bearer token non fourni");
// 	}
// 	const response = await axios.delete(
// 		`${WORKERS.trigger}?taskId=products/create&siteId=${siteId}&runId=${runId}${triggerSecretKey}`,
// 	);

// 	return response.data;
// }

// const maxSize = 150000;

// const calculateChunkSize = (chunk: Product[]) => {
// 	return new Blob([JSON.stringify(chunk)]).size;
// };

export async function createProductWithQueue(
	products: RowData[],
	userId: string,
	productChannels: string[],
	passDelayControl = false,
) {
	if (products.length === 1) {
		createSingleProductWithQueue(products[0], userId, productChannels);
		return;
	}

	const payload = convertRowDataToProduct(products, false, productChannels);
	const data = [];
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) {
		throw new Error("Bearer token non fourni");
	}
	if (!siteId) {
		throw new Error("Vous n'êtes plus connecté");
	}
	for (let i = 0; i < payload.length; i += 50) {
		await new Promise((resolve) => setTimeout(resolve, 500));
		const productChunk = payload.slice(i, i + 50);

		const payloads = productChunk.map((product) => ({
			siteId,
			productDto: product,
			userId,
		}));

		try {
			const triggerSecretKey = process.env.REACT_APP_TRIGGER_SECRET_KEY
				? `triggerSecretKey=${process.env.REACT_APP_TRIGGER_SECRET_KEY}`
				: "";

			const passDelayControlParam = passDelayControl
				? "passDelayControl=true"
				: "";

			const queryParams = [triggerSecretKey, passDelayControlParam]
				.filter(Boolean)
				.join("&");
			const response = await axios.post(
				`${WORKERS.trigger}${queryParams ? `?${queryParams}` : ""}`,
				{
					siteId: siteId,
					taskId: "products/create",
					payload: payloads,
				},
			);
			data.push(response);
		} catch (error) {
			console.error(`Erreur lors de la création des produits : ${error}`);
			throw new Error(`Erreur lors de la création des produits : ${error}`);
		}
	}

	return data;
}

export async function updateProductWithQueue(
	products: RowData[],
	userId: string,
	dbData: [],
) {
	if (products.length === 1) {
		updateSingleProductWithQueue(products[0], userId, dbData);
		return;
	}

	const productFull = convertRowDataToProduct(products, true);

	const product = sortOnlyUpdatedData(productFull, dbData);

	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const data = [];

	if (!bearerToken) throw new Error("Bearer token non fourni");
	if (!siteId) throw new Error("Vous n'êtes plus connecté");

	for (let i = 0; i < product.length; i += 50) {
		await new Promise((resolve) => setTimeout(resolve, 500));
		const productChunk = product.slice(i, i + 50);

		for (const product of productChunk) {
			if (!product.id || !product.external_id) {
				if (product.variants) {
					throw new Error(
						`Le produit ${product.variants[0].barcode} n'a pas d'ID`,
					);
				}
			}
		}
		const payloads = productChunk.map((product) => ({
			productDto: product,
			siteId,
			userId,
			multisite: true,
			external_id: product.external_id,
		}));

		try {
			const response = await axios.put(
				`${WORKERS.trigger}${triggerSecretKey}`,
				{
					siteId,
					taskId: "products/update",
					payload: payloads,
				},
			);
			data.push(response);
		} catch (error) {
			console.error(`Erreur lors de la mise à jour des produits : ${error}`);
			throw new Error(`Erreur lors de la mise à jour des produits : ${error}`);
		}
	}
	return data;
}

export async function updateSingleProductWithQueue(
	product: RowData,
	userId: string,
	dbData?: [],
) {
	const productFull = convertRowDataToProduct([product], true, undefined, true);
	const payload = sortOnlyUpdatedData(productFull, dbData);

	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) throw new Error("Bearer token non fourni");

	try {
		const response = await axios.post(`${WORKERS.trigger}${triggerSecretKey}`, {
			taskId: "products/update/multi",
			payload: {
				siteId,
				productDtos: [payload[0]],
				userId,
			},
		});
		return response.data;
	} catch (error) {
		console.error(`Erreur lors de la mise à jour du produit : ${error}`);
		throw new Error(`Erreur lors de la mise à jour du produit : ${error}`);
	}
}

export async function createSingleProductWithQueue(
	product: RowData,
	userId: string,
	productChannels: string[],
) {
	const payload = convertRowDataToProduct([product], false, productChannels);
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	if (!bearerToken) throw new Error("Bearer token non fourni");

	try {
		const response = await axios.post(WORKERS.trigger, {
			taskId: "products/create/multi",
			payload: {
				siteId,
				productDtos: [payload[0]],
				userId,
			},
		});
		return response.data;
	} catch (error) {
		console.error(`Erreur lors de la création du produit : ${error}`);
		throw new Error(`Erreur lors de la création du produit : ${error}`);
	}
}

export async function getProductsChannels() {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/publications`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	return response.data.data;
}

export async function getAllProductsChannelsTypesLinks() {
	const siteId = queryParameters.get("siteId");
	const bearerToken = queryParameters.get("act");
	const response = await axios.get(
		`${WORKERS.products}/${siteId}/publication-type-links`,
		{
			headers: {
				Authorization: bearerToken,
			},
		},
	);

	return response.data.data;
}
